import * as yup from 'yup';
import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';

import { PERSON_PHONE_TYPE_CODE } from 'services/telephoneNumbers';
import { BusinessDetailsSchema } from 'services/businesses';
import { PersonDetailsSchema } from 'services/persons';
import { CountrySchema } from 'services/countries';
import { TC_ROLES } from 'common/compliance';
import { EMAIL_REGEX } from 'utils/helpers';
import {
  PersonProps,
  PersonTelephoneNumberXrefProps,
  TelephoneNumberProps,
  UserProps,
} from 'entities/profile';

export const onboardingSteps = {
  business: 1,
  dnb: 2,
  person: 3,
} as const;

export type OnboardingSteps =
  typeof onboardingSteps[keyof typeof onboardingSteps];

export const getPersonInfoSchema = (t) =>
  yup
    .object()
    .shape({
      givenName1: yup
        .string()
        .trim()
        .required(t('profile:personInformation.firstname.validation.required')),
      surnameFirst: yup
        .string()
        .trim()
        .required(t('profile:personInformation.lastname.validation.required')),
      phone: yup
        .string()
        .trim()
        .test(
          'phone',
          t('profile:personInformation.phone.validation.valid'),
          (value) => (value ? isPossiblePhoneNumber(value || '') : true),
        )
        .required(t('profile:personInformation.phone.validation.required')),
      businessesPersonsRole: yup
        .string()
        .trim()
        .required(t('profile:personInformation.role.validation.required')),
    })
    .required();

export const getBusinessInfoSchema = (t) =>
  yup
    .object()
    .shape({
      businessLegalName: yup
        .string()
        .trim()
        .required(t('profile:bussinessProfile.name.validation.required')),
      addressLine1: yup
        .string()
        .trim()
        .required(t('profile:bussinessProfile.address.validation.required')),
      city: yup
        .string()
        .trim()
        .required(t('profile:bussinessProfile.city.validation.required')),
      stateOrProvince: yup
        .string()
        .trim()
        .required(t('profile:bussinessProfile.state.validation.required')),
      postalCode: yup
        .string()
        .trim()
        .required(t('profile:bussinessProfile.code.validation.required')),
      countryIsocode: yup
        .string()
        .trim()
        .required(t('profile:bussinessProfile.country.validation.required')),
      emailAddress: yup
        .string()
        .trim()
        .required(
          t('profile:personInformation.businessEmail.validation.required'),
        )
        .matches(
          EMAIL_REGEX,
          t('profile:personInformation.businessEmail.validation.valid'),
        ),
      phone: yup
        .string()
        .trim()
        .test(
          'phone',
          t('profile:bussinessProfile.phone.validation.valid'),
          (value) => (value ? isPossiblePhoneNumber(value) : true),
        )
        .required(t('profile:bussinessProfile.phone.validation.required')),
    })
    .required();

export const isBusinessUpdated = (data, profile) => {
  return (
    data.businessLegalName !== profile.business.businessLegalName ||
    data.addressLine1 !== profile.businessAddress.addressLine1 ||
    data.city !== profile.businessAddress.city ||
    data.stateOrProvince !== profile.businessAddress.stateOrProvince ||
    data.postalCode !== profile.businessAddress.postalCode ||
    data.countryIsocode !== profile.businessAddress.countryIsocode
  );
};

const mappedTelephoneNumber = (data: any) => {
  const parsePhone = parsePhoneNumber(data.phone);

  return {
    countryCallingCode: `+${parsePhone?.countryCallingCode || ''}-`,
    telephoneNumber: `${parsePhone?.nationalNumber || ''}`,
    telephoneNumber1: `${parsePhone?.nationalNumber || ''}`,
    isActive: true,
  };
};

export const mappedCreateTelephoneNumber = (
  data: any,
  emailAddress: string,
) => ({
  ...mappedTelephoneNumber(data),
  createdBy: data.emailAddress || emailAddress,
});

export const mappedUpdateTelephoneNumber = (
  data: any,
  telephoneNumbersId: number,
  emailAddress: string,
) => ({
  ...mappedTelephoneNumber(data),
  modifiedBy: data.emailAddress || emailAddress,
  telephoneNumbersId: telephoneNumbersId || data.telephoneNumbersId,
});

export const mappedCreateBusiness = (data: any, tenantsId: number) => ({
  createdBy: data.emailAddress,
  isActive: true,
  masterBusinessesId: null,
  businessLegalName: data.businessLegalName,
  doingBusinessAs: '',
  dunsnumber: '',
  noDunslisted: false,
  productsSold: '',
  referenceNumber: '',
  businessChannelTypesid: null,
  businessTypesId: null,
  tenantId: tenantsId,
  businessStatusesId: null,
  businessesLegalEntityFormTypesId: null,
});

export const mappedUpdateBusiness = (data, profile) => {
  const fields = {
    businessLegalName: data.businessLegalName,
    businessesId: profile.business.businessesId,
    modifiedBy: profile.person.emailAddress,
    dunsnumber: '',
    noDunslisted: false,
    isActive: true,
  };

  return fields;
};

export const mappedBusinessAddress = (data: any, address: any) => {
  const fields: any = {
    isActive: true,
    addressLine1: data.addressLine1,
    addressLine2: '',
    addressLine3: '',
    city: data.city,
    country1: address.country,
    alpha2Code: address.alpha2Code,
    stateOrProvince: data.stateOrProvince,
    countryIsocode: data.countryIsocode,
    postalCode: data.postalCode,
  };

  if (address.addressesId) {
    fields.addressesId = address.addressesId;
    fields.modifiedBy = data.emailAddress;
  } else {
    fields.createdBy = data.emailAddress;
  }

  return fields;
};

const getPersonProps = (data: PersonDetailsSchema) => ({
  personsId: data.id,
  isActive: true,
  givenName1: data.givenName1,
  givenName2: data.givenName2,
  middleName: data.middleName,
  preferredName: data.preferredName,
  emailAddress: data.emailAddress,
  prefix: data.prefix,
  suffix: data.suffix,
  surnameFirst: data.surnameFirst,
  surnameSecond: data.surnameSecond,
});

const getPersonTelephoneProps = (data: PersonDetailsSchema) => ({
  telephoneNumbersId: data.phone.id,
  countryCallingCode: data.phone.countryCallingCode,
  telephoneNumber: data.phone.number,
  isActive: true,
});

const getPersonTelephoneXrefProps = (data: PersonDetailsSchema) => ({
  personsId: data.id,
  personsTelephoneNumbersXrefid: data.phone.personsTelephoneNumbersXrefId,
  telephoneNumbersId: data.phone.id,
  telephoneNumbersTypeCode: PERSON_PHONE_TYPE_CODE,
  isActive: true,
});

const getBusinessProps = (profileData: BusinessDetailsSchema) => {
  const parsedPreferences = JSON.parse(profileData.erpPreferencesData || '""');

  const erpPreferencesData = Array.isArray(parsedPreferences)
    ? parsedPreferences.find((p) => p.IsActive)
    : null;

  const contactEmailAddress = erpPreferencesData
    ? erpPreferencesData.contactEmailAddress
    : '';
  const contactTelephoneNumber = erpPreferencesData
    ? `${erpPreferencesData.CountryCallingCode}${erpPreferencesData.TelephoneNumber}`
    : '';

  return {
    businessesId: profileData.businessesId,
    businessChannelTypesid: profileData.businessChannelTypesid,
    businessLegalName: profileData.businessLegalName,
    businessStatusesId: profileData.businessStatusesId,
    businessTypesId: profileData.businessTypesId,
    businessesLegalEntityFormTypesId:
      profileData.businessesLegalEntityFormTypesId,
    doingBusinessAs: profileData.doingBusinessAs,
    dunsnumber: (profileData.dunsNumber || '').trim(),
    noDunslisted: profileData.noDUNSListed,
    isActive: profileData.businessIsActive,
    masterBusinessesId: profileData.masterBusinessesId,
    productsSold: profileData.productsSold,
    referenceNumber: profileData.referenceNumber,
    tenantId: profileData.tenantId,
    accountholder: profileData.accountholder,
    participantID: profileData.participantID,
    erpClientID: profileData.erpClientID,
    emailAddress: profileData.emailAddress,
    faviconFileShareURI: profileData.faviconFileShareURI,
    primaryURL: profileData.primaryURL,
    applicationStatusID: profileData.applicationStatusID,
    applicationStatusTypeCode: profileData.applicationStatusTypeCode,
    acceptedTandC: profileData.acceptedTandC,
    acceptedTandCDate: profileData.acceptedTandCDate,
    completionPercentage: profileData.completionPercentage,
    complianceStatusID: profileData.complianceStatusID,
    complianceUpdatedBy: profileData.complianceUpdatedBy,
    complianceUpdateDate: profileData.complianceUpdateDate,
    complianceReference: profileData.complianceReference,
    contactEmailAddress: contactEmailAddress,
    contactTelephoneNumber: contactTelephoneNumber,
    logoFileShareURI: profileData.logoFileShareURI,
    signatoryAttestationPersonsID: profileData.signatoryAttestationPersonsID,
    singleOwner25Percent: profileData.singleOwner25Percent,
    hubSpotCompanyId: profileData.hubSpotCompanyId,
    hubSpotDealId: profileData.hubSpotDealId,
    hubSpotDealStageId: profileData.hubSpotDealStageId,
    hubSpotDealStageDescription: profileData.hubSpotDealStageDescription,
    hubSpotCreatedDateTime: profileData.hubSpotCreatedDateTime,
    productIdSentToErp: profileData.productIdSentToErp,
    productValidatorPersonId: profileData.productValidatorPersonId,
    smartDisburseProductsId: profileData.smartDisburseProductsId,
    createdBy: profileData.businessCreatedBy,
    createdOn: profileData.businessCreatedOn,
    modifiedBy: profileData.businessModifiedBy,
    modifiedOn: profileData.businessModifiedOn,
  };
};

const getBusinessAdminProps = (personXrefs: any) => {
  const admninXref = Array.isArray(personXrefs)
    ? personXrefs.find((p) => p.businessesAdmin || p.BusinessesAdmin)
    : null;

  return {
    emailAddress: admninXref?.EmailAddress || '',
    countryCallingCode: admninXref?.personCountryCallingCode || '',
    telephoneNumber: admninXref?.TelephoneNumber || '',
  };
};

const getBusinessAddressProps = (data: BusinessDetailsSchema) => ({
  addressesId: data.businessAddressesId,
  isActive: data.businessAddressIsActive,
  addressLine1: data.businessAddressLine1,
  addressLine2: data.businessAddressLine2,
  addressLine3: data.businessAddressLine3,
  city: data.businessCity,
  stateOrProvince: data.businessStateOrProvince,
  countryIsocode: data.businessCountryISOCode,
  country: data.businessCountry,
  postalCode: data.businessPostalCode,
  alpha2Code: data.businessAlpha2Code,
});

const getBusinessTelephoneProps = (data: BusinessDetailsSchema) => ({
  telephoneNumbersId: data.businessTelephoneNumberId,
  countryCallingCode: data.businessCountryCallingCode,
  telephoneNumber: data.businessTelephoneNumber,
  isActive: true,
});

const getBusinessTelephoneXrefProps = (data, businessesId) => ({
  businessesId,
  businessesTelephoneNumbersXrefId: data.businessesTelephoneNumbersXrefId,
  telephoneNumbersId: data.businessTelephoneNumberId,
  telephoneNumbersTypeCode: data.businessTelephoneType,
  isActive: data.businessesTelephoneNumbersXrefIsActive,
});

const getBusinessPersonProps = (data: any, businessId: number) => ({
  businessesPersonsXrefid: data.Businesses__Persons_XREFId,
  businessesId: businessId,
  personsId: data.PersonsId,
  businessesPersonsRole: data.BusinessesPersonsRole,
});

export const getMappedCountries = (countries: CountrySchema[]) => {
  return countries.map(({ country1, isocode, alpha2Code, countriesId }) => ({
    countriesId,
    name: country1,
    alpha2Code,
    value: isocode,
    icon: `/assets/flags/${alpha2Code.toLowerCase()}.png`,
  }));
};

export const getMappedBusiness = (
  data: BusinessDetailsSchema | null,
  email: string,
) => {
  if (!data) {
    return {};
  }

  const business = getBusinessProps(data);
  const businessAddress = getBusinessAddressProps(data);
  const businessTelephoneNumber = getBusinessTelephoneProps(data);
  const businessTelephoneNumberXref = getBusinessTelephoneXrefProps(
    data,
    business.businessesId,
  );

  const personXrefs = JSON.parse(data.businesses__Persons_XREF || '""');
  const personXref = Array.isArray(personXrefs)
    ? personXrefs.find((p) => p.EmailAddress === email)
    : null;

  const businessPerson = getBusinessPersonProps(
    personXref,
    business.businessesId,
  );

  const businessAdmin = getBusinessAdminProps(personXrefs);

  const showMembers = personXref
    ? personXref.BusinessesAdmin ||
      personXref.BusinessesCreateForOtherPersonInTenant ||
      TC_ROLES.includes((personXref.BusinessesPersonsRole || '').toLowerCase())
    : false;

  return {
    business,
    businessAdmin,
    businessPerson,
    businessAddress,
    businessTelephoneNumber,
    businessTelephoneNumberXref,
    showMembers,
  };
};

export const getMappedPerson = (data: PersonDetailsSchema) => {
  const person: PersonProps = getPersonProps(data);
  const personTelephoneNumber: TelephoneNumberProps =
    getPersonTelephoneProps(data);
  const personTelephoneNumberXref: PersonTelephoneNumberXrefProps =
    getPersonTelephoneXrefProps(data);

  return {
    person,
    personTelephoneNumber,
    personTelephoneNumberXref,
  };
};

export const getProfileData = (
  person: PersonDetailsSchema,
  business: BusinessDetailsSchema | null,
) => {
  return {
    ...getMappedPerson(person),
    ...getMappedBusiness(business, person.emailAddress),
  };
};

export const getMappedNewPersonData = (user: Partial<UserProps>) => {
  const name = user.name?.split(' ') || ['', ''];

  return {
    createdBy: user.username,
    isActive: true,
    givenName1: name[0] ?? '',
    givenName2: '',
    emailAddress: user.username,
    surnameFirst: name[1] || '',
    surnameSecond: '',
    middleName: '',
    preferredName: '',
    prefix: '',
    suffix: '',
  };
};
