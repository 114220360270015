export interface BusinessesOwnersPerson {
  businessesOwnersPersonsId: number;
  isActive: boolean;
  businessesId: number;
  businessesOwnersStatusesId: number;
  prefix: string;
  givenName1: string;
  givenName2: string;
  middleName: string;
  preferredName: string;
  suffix: string;
  surnameFirst: string;
  surnameSecond: string;
  emailAddress: string;
  dateOfBirth: string;
  idnumber: string;
  idcountryIsocode: string;
  idexpirationDate: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  stateOrProvince: string;
  countryIsocode: string;
  postalCode: string;
  countryCallingCode: string;
  telephoneNumber: string;
  nationalityCountryISOCode: string;
  ownershipPercentage: number;
  personDocumentsTypesId: number;
  idfrontGuid: string;
  idfrontBlobFileSize: number;
  idfrontBlobFileExtension: string;
  idfrontBlobFileName: string;
  idbackGuid: string;
  idbackBlobFileSize: number;
  idbackBlobFileExtension: string;
  idbackBlobFileName: string;
  proofOfAddressGuid: string;
  proofOfAddressBlobFileSize: number;
  proofOfAddressBlobFileExtension: string;
  proofOfAddressBlobFileName: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface BusinessesOwnersBlobReference {
  businessesOwnersBlobReferencesId: number;
  isActive: boolean;
  businessesId: number;
  blobReferencesTypesId: number;
  blobGuid: string;
  blobFileSize: number;
  blobFileExtension: string;
  blobFileName: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface BusinessesOwnersBusiness {
  businessesOwnersBusinessesId: number;
  isActive: boolean;
  businessesId: number;
  businessLegalName: string;
  doingBusinessAs: string;
  dunsnumber: string;
  noDunslisted: boolean;
  referenceNumber: string;
  businessesOwnersStatusesId: number;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  stateOrProvince: string;
  countryIsocode: string;
  postalCode: string;
  countryCallingCode: string;
  businessContactNumber: string;
  businessContactName: string;
  businessesLegalEntityFormTypesId: number;
  ownershipPercentage: number;
  emailAddress: string;
  countryofIncorporationIsocode: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
  type: number;
}

export enum OwnerTypes {
  'person' = 0,
  'business' = 1,
}

export interface OwnerType {
  type: number;
  isActive: boolean;
  ownershipPercentage?: number;
  businessesOwnersPersonsId?: number;
  businessesOwnersBusinessesId?: number;
}

export interface OwnershipState {
  owners: OwnerType[];
  blobReferences: BusinessesOwnersBlobReference[];
  isLoading: boolean;
  loadingProgress: number;
}
