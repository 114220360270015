import { PermissionSchema } from 'services/security';

export interface ApplicationStatus {
  applicationStatusId: number;
  description: string;
  isActive: boolean;
  typeCode: string;
}

export interface BankAccountStatus {
  bankAccountStatusesId: number;
  description: string;
  isActive: boolean;
}

export interface ERPAccountStatus {
  eRPAccountStatusesId: number;
  description: string;
  isActive: boolean;
}

export enum ConnectorTypes {
  erp = 'erp',
  bank = 'bank',
}

export enum OnboardingTabCodes {
  businessInfo = 'businessInfo',
  owner = 'owner',
  financial = 'financial',
  transactional = 'transactional',
  businessPlan = 'businessPlan',
  connectors = 'connectors',
  configuration = 'configuration',
}

export interface BlobReferencesType {
  blobReferencesTypesId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
}

export interface BusinessLegalEntityFormTypes {
  businessesLegalEntityFormTypesId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  description: string;
}

export interface BusinessIdType {
  businessIdTypesId: number;
  description: string;
  isActive: boolean;
  isMCTrackAllowed: boolean;
  typeCode: string;
}

export interface BusinessesDocumentsType {
  businessesDocumentsTypesId: number;
  description: string;
  isActive: boolean;
  typeCode: string;
  isMCTrackAllowed: boolean;
  idDropdown: boolean;
  documentDropdown: boolean;
}

export interface CurrencyCode {
  currencyCodeID: number;
  isActive: boolean;
  currencyName: string;
  currencyShortName: string;
  alphaCode: string;
  numericCode: number;
  numericCodeConverted: string;
  exponent: number;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface PCICompliant {
  pcicompliantId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface MerchantCategoryCode {
  merchantCategoryCodesId: number;
  source: string;
  category: string;
  specialty: string;
  code: string;
  description: string;
  specialtyRequirement: string;
  createdOn?: string;
  createdBy?: string;
  modifiedOn?: string;
  modifiedBy?: string;
}

export interface OperatingStatus {
  operatingStatusId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface BusinessStatus {
  businessStatusesId: number;
  isActive: boolean;
  description: string;
  createdOn?: string;
  createdBy?: string;
  modifiedOn?: string;
  modifiedBy?: string;
}

export interface SubscriptionHowOften {
  subscriptionsHowOftenId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface SalesDelayedPaymentPeriod {
  salesDelayedPaymentPeriodsId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface CreditCardNetwork {
  creditCardNetworksId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface PersonsDocumentsType {
  personDocumentsTypesId: number;
  isActive: boolean;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface CountryScema {
  countriesId: number;
  isActive: boolean;
  country: string;
  alpha2Code: string;
  alpha3Code: string;
  uNCode: string;
  iSOCode: string;
  createdOn?: string;
  createdBy?: string;
  modifiedOn?: string;
  modifiedBy?: string;
}

export interface Country extends CountryScema {
  name: string;
  value: string;
  icon: string;
}

export interface BusinessesFinancialsGeographicSalesType {
  businessesFinancialsGeographicSalesTypesId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  logoReference: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface IndustryType {
  industryTypesId: number;
  isActive: boolean;
  typeCode: string;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface GeographicType {
  geogrphicTypesId: number;
  description: string;
  isActive: boolean;
  typeCode: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface DemographicType {
  demographicTypesId: number;
  description: string;
  isActive: boolean;
  typeCode: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

type MappedBlobReferencesType = {
  [key: string]: number;
};

export interface DropdownsProps {
  applicationStatus: ApplicationStatus[];
  bankAccountStatuses: BankAccountStatus[];
  businessIdTypes: BusinessIdType[];
  businessesDocumentsTypes: BusinessesDocumentsType[];
  blobReferencesTypes: BlobReferencesType[];
  mappedBlobReferencesTypes: MappedBlobReferencesType;
  businessesLegalEntityFormTypes: BusinessLegalEntityFormTypes[];
  currencyCodes: CurrencyCode[];
  erpAccountStatuses: ERPAccountStatus[];
  inputCurrencies: CurrencyCode[];
  pciCompliant: PCICompliant[];
  merchantCategoryCodes: MerchantCategoryCode[];
  operatingStatus: OperatingStatus[];
  businessStatuses: BusinessStatus[];
  subscriptionsHowOften: SubscriptionHowOften[];
  salesDelayedPaymentPeriods: SalesDelayedPaymentPeriod[];
  creditCardNetworks: CreditCardNetwork[];
  businessesFinancialsGeographicSalesTypes: BusinessesFinancialsGeographicSalesType[];
  personsDocumentsTypes: PersonsDocumentsType[];
  countries: Country[];
  industryTypes: IndustryType[];
  geogrphicTypes: GeographicType[];
  demographicTypes: DemographicType[];
}

export interface DashboardState {
  isLoading: boolean;
  onboardingTab: number;
  onboardingTabCode: OnboardingTabCodes;
  permissions: PermissionSchema[];
  dropdowns: DropdownsProps;
}

export enum Permissions {
  financialInfo = 'Financial',
  bankIntegration = 'Integrations_Banks',
  invite = 'Invite',
  compliance = 'Compliance',
  erpIntegration = 'SystemsOfRecords_SourceTypes',
  submitApp = 'Submit_Enabled',
  products = 'Products',
  ownership = 'Ownership',
  configuration = 'Configuration',
  onboardingBusiness = 'Businesses',
  implementations = 'Implementations',
  transactional = 'Transactional',
  fxProvider = 'Crossborder_Setup',
}

export enum BlobFileTypes {
  license = 'Business or Service License',
  bankAccountStatement = 'Bank Account Statement',
  bankAccountStatement6 = 'Bank Account Statement - past 6 Months',
  termsAndConditions = 'T&Cs',
  shareholder = 'Shareholder Register',
  auditedFinancials = 'Audited Financials',
  federalTaxId = 'Proof of Federal Tax Identification Number/VAT',
  certificate = 'Certificate of Good Standing',
  sourceOfFunds = 'Evidence for the Source of Funds',
  letterFromBank = 'Depository Account Voided Check or Letter from Bank',
  voidedCheck = 'Voided Check for ABA/DDA',
  dbaStatement = 'DBA Business Name Statement',
  organization = 'Organization Document',
  merchantVarSheet = 'Merchant Service Details - VAR Sheet',
  accountSignatory = 'ID for the account signatory',
  interimFinancials = 'Interim Financials',
}
