import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useState } from 'react';

import {
  getErpAccounts,
  getHasBankAccountRelation,
} from 'pages/dashboard/components/AccountingSoftware/selectors';
import ConfirmDisconnect from 'pages/dashboard/components/Connectors/ConfirmDisconnect';
import { BankAccount, BankAccountStatusIds } from 'entities/bankAccounts';
import { PencilIcon, DisconnectIcon, CheckIcon } from 'components/icons';
import { getCountries, hasPermission } from 'pages/dashboard/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import { StateOptionType, statesByCountry } from 'utils/address';
import { useAppDispatch, useAppSelector } from 'hooks';
import bankImage from 'assets/images/bank-account.png';
import Alert, { AlertTypes } from 'components/Alert';
import HideComponent from 'components/HideComponent';
import { ERPProviders } from 'entities/accounting';
import Autocomplete from 'components/Autocomplete';
import { getSageBankAccounts } from 'services/erp';
import { Permissions } from 'entities/dashboard';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Modal from 'components/Modal';

import BankAccountStatus from '../../Connectors/BankAccountStatus';
import BankAccountInfoNumbers from './BankAccountInfoNumbers';
import BankAccountInfoChecks from './BankAccountInfoChecks';
import BankAccountReference from './BankAccountReference';
import { getERPBankAccounts } from '../api';
import {
  resetBankAccountInfoModal,
  setIsBankAccountInfoOpen,
  setIsBankAccountOpen,
} from '../bankAccountsSlice';
import {
  getBankAccount,
  getUsedBankAccountNames,
  getIsBankAccountInfoOpen,
} from '../selectors';
import {
  connectBankAccount,
  disconnectBankAccount,
  updateBankAccount,
} from '../thunks';

const BankAccountInfoModal = () => {
  const { t } = useTranslation(['dashboard', 'bank']);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true);
  const [bankNameDisabled, setBankNameDisabled] = useState<boolean>(false);
  const [bankName, setBankName] = useState<any>();

  const account = useAppSelector(getBankAccount) as BankAccount;
  const usedBankNames = useAppSelector(getUsedBankAccountNames);
  const countries = useAppSelector(getCountries);
  const erpAccounts = useAppSelector(getErpAccounts);
  const isNameMissing: boolean = useAppSelector(getHasBankAccountRelation);
  const bankPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.bankIntegration),
  );
  const compliancePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.compliance),
  );
  const isBankAccountInfoOpen: boolean = useAppSelector(
    getIsBankAccountInfoOpen,
  );

  const status =
    account?.bankAccountStatus || t('dashboard:connectors.inProgress') || '';
  const country = countries.find((c) => c.iSOCode === account?.countryISOCode);
  const state =
    country?.iSOCode && statesByCountry[country?.iSOCode]
      ? statesByCountry[country?.iSOCode].find(
          (s: StateOptionType) => s.value === account?.stateOrProvince,
        )?.name
      : account?.stateOrProvince;
  const connectorName =
    erpAccounts.find((erp) => erp.isActive)?.connectorName || '';

  const fetchERPBanks = async (search: string) => {
    try {
      setBankNameDisabled(false);

      let response: any = null;

      if (connectorName === ERPProviders.intacct) {
        const activeERP = erpAccounts.find((erp) => erp.isActive);

        response = await getSageBankAccounts({
          erpAccountId: activeERP.erpaccountsId,
          connectorId: activeERP.connectorId,
          routingNumber: account.routingNumber,
          bankAccountNumber: account.accountNumber,
        });
      } else {
        response = await getERPBankAccounts(
          ERPProviders.quickbooks,
          account.businessesId,
        );
      }

      setBankName(
        response.find(
          (option) =>
            (option.active === undefined || option.active) &&
            account.erpBankAccountReferenceID === option.id,
        ) || null,
      );
      setIsOptionsLoading(false);

      return response
        .filter(
          (option) =>
            (option.active === undefined || option.active) &&
            (option.name.toLowerCase().indexOf(search.toLowerCase()) === 0 ||
              option.id === search),
        )
        .map((option) => ({
          ...option,
          isDisabled:
            usedBankNames.includes(option.id) &&
            option.id !== account.erpBankAccountReferenceID,
        }));
    } catch (e) {
      setBankNameDisabled(true);

      const option = {
        id: account.erpBankAccountReferenceID,
        name: account.erpBankAccountName,
      };

      if (option.id) {
        setBankName(option);
        return [option];
      }

      return [];
    }
  };

  const closeModal = () => {
    setBankName(null);
    setIsOptionsLoading(true);
    dispatch(resetBankAccountInfoModal());
  };

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const handleConnect = async () => {
    setIsLoading(true);
    await dispatch(connectBankAccount(account));
    setIsLoading(false);
  };

  const disconnectBank = async () => {
    setIsLoading(true);
    handleCancel();
    await dispatch(disconnectBankAccount(account.bankAccountsId));
    setIsLoading(false);
    closeModal();
  };

  const handleDisconnect = () => {
    dispatch(
      openDialog({
        content: (
          <ConfirmDisconnect
            onConfirm={disconnectBank}
            onCancel={handleCancel}
          />
        ),
        maxWidth: '514px',
      }),
    );
  };

  const handleEdit = () => {
    dispatch(setIsBankAccountInfoOpen(false));
    dispatch(setIsBankAccountOpen(true));
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (bankName) {
      await dispatch(
        updateBankAccount({
          erpBankAccountName: bankName.name,
          erpBankAccountReferenceID: bankName.id,
        }),
      );
    }
    setIsLoading(false);
    closeModal();
  };

  const handleChange = (option) => {
    setBankName(option);
  };

  const optionLabel = (option: any, { context, selectValue }: any) => {
    const isSelected = selectValue.find(
      (selected) => selected.id === option.id,
    );

    return (
      <div
        className={classNames(
          'flex items-center relative',
          context === 'menu' && isSelected && 'pr-6',
        )}
      >
        <span className="text-gray-900">{option.name}</span>
        <span className="text-sm text-gray-500 ml-2">{option.id}</span>
        {context === 'menu' && isSelected && (
          <span className="absolute inset-y-0 right-0 flex items-center">
            <CheckIcon className="selected-icon" aria-hidden="true" />
          </span>
        )}
      </div>
    );
  };

  if (!account) {
    return null;
  }

  return (
    <Modal
      isOpen={isBankAccountInfoOpen}
      closeModal={closeModal}
      maxWidth="754px"
      title={account.userCalledName || account.accountName || ''}
      actions={
        <div className="flex justify-end">
          <Button
            type="button"
            className="w-48"
            onClick={handleSave}
            disabled={
              !bankName || account.erpBankAccountReferenceID === bankName.id
            }
          >
            {t('common:save')}
          </Button>
        </div>
      }
      closeTimeoutMS={0}
    >
      <div className="p-6 flex-1 overflow-auto">
        <Loading loading={isLoading} fullHeight transparent />
        <div className="flex justify-between items-center mb-5 p-4 rounded-10 w-full bg-gray-50">
          <div className="flex items-center">
            <img src={bankImage} alt="Bank Account" className="h-8 w-8" />

            <div className="ml-4 text-sm">
              <div className="flex items-center">
                <p className="font-medium text-gray-900 mr-2">
                  {account.userCalledName || account.accountName || ''}
                </p>
                <BankAccountStatus
                  statusId={account.bankAccountStatusId}
                  status={status}
                />
              </div>
              <p className="text-secondary">
                {account.accountNumberLast4
                  ? account.bankName
                    ? t('dashboard:connectors.bank.info', {
                        name: account.bankName,
                        number: account.accountNumberLast4,
                      })
                    : t('dashboard:connectors.bank.infoNumber', {
                        number: account.accountNumberLast4,
                      })
                  : account.bankName || ''}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <PermissionSection
              permission={Permissions.bankIntegration}
              showPopover
              edit
            >
              <Button
                variant="secondary-outline"
                size="medium"
                className="ml-4"
                onClick={handleEdit}
                disabled={!bankPermissions.edit}
              >
                <PencilIcon className="mr-2 text-gray-500" />
                {t('common:edit')}
              </Button>
            </PermissionSection>
            <HideComponent
              show={
                BankAccountStatusIds.connected !==
                  account.bankAccountStatusId &&
                bankPermissions.edit &&
                compliancePermissions.view
              }
            >
              <PermissionSection
                permission={Permissions.compliance}
                showPopover
                edit
              >
                <Button
                  variant="secondary-outline"
                  size="medium"
                  className="ml-4"
                  onClick={handleConnect}
                  disabled={
                    !bankPermissions.edit || !compliancePermissions.edit
                  }
                >
                  {t('dashboard:connectors.connected')}
                </Button>
              </PermissionSection>
            </HideComponent>

            <PermissionSection
              permission={Permissions.bankIntegration}
              showPopover
              edit
            >
              <Button
                variant="link-red"
                size="medium"
                className="ml-4"
                onClick={handleDisconnect}
                disabled={!bankPermissions.edit}
              >
                <DisconnectIcon className="mr-2" />
                {t('common:disconnect')}
              </Button>
            </PermissionSection>
          </div>
        </div>

        {isNameMissing && (
          <div className="pt-1">
            <Autocomplete
              label={t('dashboard:connectors.bank.erpBankName', {
                erp: connectorName,
              })}
              placeholder={t('dashboard:connectors.bank.selectBankName')}
              className="max-w-[340px]"
              value={bankName}
              onChange={handleChange}
              formatOptionLabel={optionLabel}
              loadOptions={fetchERPBanks}
              disabled={!bankPermissions.edit || bankNameDisabled}
              async
            />
            {!bankName &&
              (!isOptionsLoading || !account.erpBankAccountReferenceID) && (
                <Alert
                  type={AlertTypes.warning}
                  title={t('dashboard:alerts.bankAccount.nameMissingTitle')}
                  description={t(
                    'dashboard:alerts.bankAccount.nameMissingText',
                  )}
                  className="mt-6"
                />
              )}
          </div>
        )}

        <div className="mt-6 pt-6 border-t border-gray-200 flex flex-wrap">
          <div className="w-1/2 mb-5">
            <p className="text-sm font-medium text-gray-500 mb-1">
              {t('bank:fields.userCalledName.label')}
            </p>
            <p className="text-sm">{account.accountName}</p>
          </div>
          <div className="w-1/2 mb-5">
            <p className="text-sm font-medium text-gray-500 mb-1">
              {t('common:fields.address.label')}
            </p>
            <p className="text-sm">
              {account.addressLine1}, {account.city}, {account.postalCode}
            </p>
          </div>
          <div className="w-1/2">
            <p className="text-sm font-medium text-gray-500 mb-1">
              {t('common:fields.country.label')}
            </p>
            <p className="text-sm flex items-center">
              {country?.icon && (
                <img
                  src={country.icon}
                  alt="flag"
                  className="w-5 mr-2 mt-[2px]"
                />
              )}
              {country?.name || ''}
            </p>
          </div>
          <div className="w-1/2">
            <p className="text-sm font-medium text-gray-500 mb-1">
              {t('common:fields.state.label')}
            </p>
            <p className="text-sm">{state || ''}</p>
          </div>
        </div>

        <BankAccountInfoNumbers />
        <BankAccountReference />
        <BankAccountInfoChecks />
      </div>
    </Modal>
  );
};

export default BankAccountInfoModal;
