import { VendorCredentialsSchema } from 'services/bankIntegrators';
import { FundingAccountSchema } from 'services/accounts';
import { AlertTypes } from 'components/Alert';
import {
  BankAccountCheckRangeType,
  BankAccountCheckType,
  BankAccountSchema,
} from 'services/bankAccounts';

export enum BankAccountStatusIds {
  inProgress = 1,
  connected = 2,
  connectionError = 3,
  actionRequired = 4,
}

export enum BankNames {
  BankOfAmerica = 'bank-of-america',
  JPMorgan = 'jp-morgan',
  USBank = 'us-bank',
  WellsFargo = 'wells-fargo',
  GoldmanSachs = 'goldman-sachs',
}

export enum BankLabels {
  BankOfAmerica = 'Bank of America',
  JPMorgan = 'JPMorgan Chase & Co.',
  USBank = 'US Bank',
  WellsFargo = 'Wells Fargo',
  GoldmanSachs = 'Goldman Sachs',
}

export enum BanksWithPaymentPreferences {
  GoldmanSachs = BankNames.GoldmanSachs,
  USBank = BankNames.USBank,
}

export interface BankIntegratorProps {
  banksIntegratorsId: number;
  bankName: string;
  helpText: string;
  howToBlobFileExtension: string;
  howToBlobFileName: string;
  howToBlobFileSize: number;
  howToBlobGuid: string;
  integratorsId: number;
  isActive: boolean;
  logoBlobFileExtension: string;
  logoBlobFileName: string;
  logoBlobFileSize: number;
  logoBlobGuid: string;
  url: string;
  urltext: string;
  createdBy?: string;
  createdBySp?: string;
  createdOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
  modifiedOn?: string;
}

export interface BankAccountProps extends BankAccountSchema {
  achCompanyId: string | null;
  tgpabankAccountCreatedOn: string | null;
  bankAccounts__Addresses_XREFId: number;
  integratorDirectoryId: number;
  tgpalogReferenceId: string | null;
  tgpaccountReferenceId: string | null;
  treasuryBlobdocumentId: string | null;
  treasuryBlobFileSize: string | null;
  treasuryBlobFileExtension: string | null;
  treasuryBlobFileName: string | null;
  prenoteAllowed: boolean | null;
  allDataEntered: boolean | null;
  allDataEnteredDate: string | null;
  addressesId: number;
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string;
  stateOrProvince: string;
  countryISOCode: string;
  postalCode: string;
  addressTypesId: number;
  addressesTypeCode: string;
  description: string;
  json: string | null;
  jsone: string | null;
  erpcustomerAccountId: string | null;
  tgpabankeAccountError: string | null;
  tgpaaccountHolderId: string | null;
  bankAccountStatusId: number;
  integrationStartDate: string | null;
  erpBankAccountReferenceID: string | null;
  erpBankAccountName: string | null;
  checkCustomerEnabled: boolean;
  checkOmitCustomerManaged: boolean;
  checkRangesCustomerManaged: boolean;
  referenceId: string;
  wireAllowed: boolean;
  reportingFeedsEnabled: boolean;
}

export interface BankAccount extends BankAccountProps {
  bankName: string;
  bankAccountStatus: string;
}

export interface BankAccountTypeProps {
  value: number;
  name: string;
}

export interface VendorCredentialProps {
  id: number;
  name: string;
  integratorDirectoryId: number;
}

export type AlertProps = {
  type: AlertTypes;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  buttonText?: string;
  buttonClick?: () => void;
  code?: string;
};

export interface BankAccountsState {
  bankAccounts: BankAccountProps[];
  bankAccount: BankAccountProps | null;
  fundingAccount: FundingAccountSchema | null;
  bankIntegrators: BankIntegratorProps[];
  bankAccountTypes: BankAccountTypeProps[];
  vendorCredentials: VendorCredentialsSchema | null;
  isLoading: boolean;
  isConnecting: boolean;
  isFormUpdate: boolean;
  isSuccess: boolean;
  isRefreshing: boolean;
  showBankName: boolean;
  isSubmiting: boolean;
  integrator: BankIntegratorProps | null;
  fields: VendorCredentialProps[];
  directoryId: number;
  step: number;
  alerts: AlertProps[];
  isBankAccountInfoOpen: boolean;
  isBankAccountOpen: boolean;
  isFundingAccountOpen: boolean;
  backToBankAccount: number;
  showConnectionForm: boolean;
  selectedConnection: number;
  checkRanges: BankAccountCheckRangeType[];
  checkOmitted: BankAccountCheckType[];
}
