import api from 'api';

export interface CountrySchema {
  countriesId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  country1: string;
  alpha2Code: string;
  alpha3Code: string;
  uncode: string;
  isocode: string;
}

export const searchCountries = async (data: Partial<CountrySchema>) => {
  const countries: CountrySchema[] = await api.post(
    'Countries/SearchCountries',
    data,
  );
  return countries;
};

export default {
  searchCountries,
};
