import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { equals } from 'ramda';

import { NumberInput, Select, SelectIndicatorTypes } from 'components/Inputs';
import PermissionSection from 'components/permission/PermissionSection';
import smartLogo from 'assets/images/transcard-products.png';
import { CurrencyDollarSolidIcon } from 'components/icons';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { PAYMENT_TYPES } from 'entities/configuration';
import Button, { ButtonSize } from 'components/Button';
import Label from 'components/Inputs/components/Label';
import HideComponent from 'components/HideComponent';
import { BankAccount } from 'entities/bankAccounts';
import { Permissions } from 'entities/dashboard';
import Loading from 'components/Loading';
import Popover from 'components/Popover';
import Link from 'components/Link';

import BankAccountOption from './components/BankAccountOption';
import { saveBPSTrack, saveBPSTrackPayment } from './thunks';
import { getBankAccounts } from '../BankAccounts/selectors';
import AddBankAccount from './components/AddBankAccount';
import {
  getDefaultBankAccountId,
  getCurrentBankAccountId,
  getBPSTrackConfig,
  getIsLoading,
} from './selectors';

interface MappedBankAccount extends BankAccount {
  name: string;
  value: number;
}

const SmartExchange = () => {
  const { t } = useTranslation('configuration');
  const dispatch = useAppDispatch();

  const bpsTrackConfig: any = useAppSelector(getBPSTrackConfig);
  const defaultBankAccountId: number = useAppSelector(getDefaultBankAccountId);
  const currentBankAccountId: number = useAppSelector(getCurrentBankAccountId);
  const bankAccounts: BankAccount[] = useAppSelector(getBankAccounts);
  const isLoading: boolean = useAppSelector(getIsLoading);
  const bankPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.bankIntegration),
  );
  const configPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.configuration),
  );

  const [defaultValues, setDefaultValues] = useState(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [bankAccountId, setBankAccountId] = useState<number>(0);

  useEffect(
    () => () => {
      setBankAccountId(defaultBankAccountId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    control,
    reset,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: bpsTrackConfig,
  });

  useEffect(() => {
    if (!equals(bpsTrackConfig, defaultValues)) {
      reset(bpsTrackConfig);
      setDefaultValues(bpsTrackConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bpsTrackConfig, reset]);

  useEffect(() => {
    if (defaultBankAccountId !== bankAccountId) {
      setBankAccountId(defaultBankAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBankAccountId]);

  const handleSelectAccount = (bankAccount: BankAccount) => {
    setBankAccountId(bankAccount.bankAccountsId || 0);
  };

  const validateValues = (type: string) => {
    let valid = true;
    const formValues = getValues();
    clearErrors();

    if (formValues[type].min && formValues[type].max) {
      if (+formValues[type].min > +formValues[type].max) {
        valid = false;
        setError(`${type}.min`, {
          message: t('configuration:smart.invoice.min.valid'),
        });
      }

      if (+formValues[type].max < +formValues[type].min) {
        valid = false;
        setError(`${type}.max`, {
          message: t('configuration:smart.invoice.max.valid'),
        });
      }
    }

    return valid;
  };

  const validateAmount = (type: string) => {
    setIsValid(validateValues(type));
  };

  const handleSave = async () => {
    const formValues = getValues();

    PAYMENT_TYPES.filter((type) => type.isActive).map(async (type) => {
      if (!equals(formValues[type.title], defaultValues?.[type.title])) {
        await dispatch(
          saveBPSTrackPayment({
            paymentMethod: type.title,
            minInvoiceAmount: formValues[type.title].min || 0,
            maxInvoiceAmount: formValues[type.title].max || 0,
          }),
        );
      }
    });

    if (bankAccountId !== currentBankAccountId) {
      await dispatch(saveBPSTrack({ bankAccountId }));
    }
  };

  const mappedBankAccounts: MappedBankAccount[] = useMemo(
    () =>
      bankAccounts
        .filter((account: BankAccount) => account.accountName)
        .map((account: BankAccount) => ({
          ...account,
          name: t('configuration:smart.bankAccount.selectedName', {
            name: account.accountName,
            number: account.accountNumberLast4,
          }),
          value: account.bankAccountsId,
        })),
    [bankAccounts, t],
  );

  const typeErrors: any = errors;

  return (
    <div className="bg-white shadow border rounded-10 p-6 relative mt-6">
      <HideComponent show={isLoading}>
        <Loading transparent />
      </HideComponent>

      <div className="flex items-start justify-between mb-6 pb-6 border-b border-gray-200">
        <div className="flex items-start">
          <img src={smartLogo} alt="Transcard products" />
          <div className="pl-4">
            <h6 className="font-semibold mb-0.5">
              {t('configuration:smart.title')}
            </h6>
            <div className="flex flex-wrap items-center text-sm">
              <p className="text-secondary mr-1">
                {t('configuration:smart.description')}
              </p>
              <Popover
                button={
                  <Link onClick={() => {}}>
                    {t('configuration:smart.link')}
                  </Link>
                }
                content={
                  <div className="bg-gray-900 rounded-lg p-3 md:w-[352px]">
                    <div className="bg-gray-800 p-3 rounded-lg text-sm">
                      <p className="text-gray-400">
                        {t('configuration:smart.info')}
                      </p>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <HideComponent show={configPermissions.view}>
          <PermissionSection
            permission={Permissions.configuration}
            showPopover
            edit
          >
            <Button
              onClick={handleSave}
              size={ButtonSize.medium}
              heightClass="h-9"
              className="ml-3"
              disabled={
                !isValid ||
                (bankAccountId === currentBankAccountId &&
                  equals(defaultValues, getValues())) ||
                !configPermissions.edit
              }
            >
              {t('common:save')}
            </Button>
          </PermissionSection>
        </HideComponent>
      </div>

      <PermissionSection permission={Permissions.configuration} showMessage>
        <>
          <div className="w-full max-w-[430px] mb-6">
            <HideComponent show={!bankPermissions.view}>
              <Label>{t('configuration:smart.bankAccount.label')}</Label>
            </HideComponent>
            <PermissionSection
              permission={Permissions.bankIntegration}
              showMessage
            >
              <Select
                name="bankAccountsId"
                label={t('configuration:smart.bankAccount.label')}
                placeholder={t('configuration:smart.bankAccount.placeholder')}
                indicator={SelectIndicatorTypes.selector}
                onChange={handleSelectAccount}
                optionComponent={BankAccountOption}
                optionsFooter={AddBankAccount}
                classes={{ activeOption: 'bg-blue-50 cursor-pointer' }}
                options={mappedBankAccounts}
                value={mappedBankAccounts.find(
                  (account: MappedBankAccount) =>
                    account.value === bankAccountId,
                )}
                disabled={!configPermissions.edit}
              />
            </PermissionSection>
          </div>

          <div className="w-full bg-gray-50 rounded-lg p-4 flex items-center">
            <p className="text-secondary pr-6">
              {t('configuration:smart.invoice.description')}
            </p>
            {PAYMENT_TYPES.filter((type) => type.isActive).map((type) => (
              <div className="flex items-start -px-3" key={type.id}>
                <NumberInput
                  name={`${type.title}.min`}
                  label={t('configuration:smart.invoice.min.label')}
                  placeholder={t('configuration:smart.invoice.min.placeholder')}
                  prefix={<CurrencyDollarSolidIcon className="text-gray-400" />}
                  control={control}
                  allowNegative={false}
                  readOnly={isLoading || !configPermissions.edit}
                  className="px-3 w-1/2"
                  onBlur={() => validateAmount(type.title)}
                  error={typeErrors[type.title]?.min}
                  prefixInline
                  inputClassName="pl-10"
                />
                <NumberInput
                  name={`${type.title}.max`}
                  label={t('configuration:smart.invoice.max.label')}
                  placeholder={t('configuration:smart.invoice.max.placeholder')}
                  prefix={<CurrencyDollarSolidIcon className="text-gray-400" />}
                  className="px-3 w-1/2"
                  onBlur={() => validateAmount(type.title)}
                  error={typeErrors[type.title]?.max}
                  control={control}
                  allowNegative={false}
                  readOnly={isLoading || !configPermissions.edit}
                  prefixInline
                  inputClassName="pl-10"
                />
              </div>
            ))}
          </div>
        </>
      </PermissionSection>
    </div>
  );
};

export default SmartExchange;
