import { createSelector } from '@reduxjs/toolkit';

import { BankAccountStatusIds } from 'entities/bankAccounts';
import { CountryISOCodes } from 'common/countries';
import { RootState } from 'state/store';

import { isBankAccountEmpty } from './utils';

export const getRoot = (state: RootState) => state.bankAccounts;
export const getDashboard = (state: RootState) => state.dashboard;
export const getProfile = (state: RootState) => state.profile;

export const getState = createSelector([getRoot], (state) => state);

export const getTGPAccountReferenceId = createSelector(
  [getRoot],
  ({ bankAccount }) => bankAccount?.tgpaccountReferenceId || null,
);

export const getisBankAccountOpen = createSelector(
  [getRoot],
  ({ isBankAccountOpen }) => isBankAccountOpen,
);

export const getIsBankAccountInfoOpen = createSelector(
  [getRoot],
  ({ isBankAccountInfoOpen }) => isBankAccountInfoOpen,
);

export const getIsFundingAccountOpen = createSelector(
  [getRoot],
  ({ isFundingAccountOpen }) => isFundingAccountOpen,
);

export const getBackToBankAccount = createSelector(
  [getRoot],
  ({ backToBankAccount }) => backToBankAccount,
);

export const getIntegrationAlerts = createSelector(
  [getRoot],
  ({ alerts }) => alerts,
);

export const getBankAccountsLength = createSelector(
  [getRoot],
  ({ bankAccounts }) => bankAccounts.filter((b) => b.isActive).length,
);

export const getBankAccountCheckRanges = createSelector(
  [getRoot],
  ({ checkRanges }) => checkRanges,
);

export const getBankAccountChecks = createSelector(
  [getRoot],
  ({ checkOmitted }) => checkOmitted,
);

export const getConnectedBankAccount = createSelector(
  [getRoot],
  ({ bankAccounts }) => {
    return bankAccounts.find(
      (account) =>
        account.bankAccountStatusId === BankAccountStatusIds.connected,
    );
  },
);

export const getBankAccount = createSelector(
  [getRoot, getDashboard],
  ({ bankAccount, bankIntegrators }, { dropdowns }) =>
    bankAccount
      ? {
          ...bankAccount,
          bankName:
            bankIntegrators.find(
              (integrator) =>
                integrator.banksIntegratorsId ===
                bankAccount.banksIntegratorsId,
            )?.bankName || '',
          bankAccountStatus:
            dropdowns.bankAccountStatuses.find(
              (s) =>
                s.bankAccountStatusesId === bankAccount.bankAccountStatusId,
            )?.description || '',
        }
      : null,
);

export const getFundingAccount = createSelector(
  [getRoot, getDashboard],
  ({ fundingAccount }) => fundingAccount,
);

export const getBankAccounts = createSelector(
  [getRoot, getDashboard],
  ({ bankAccounts, bankIntegrators }, { dropdowns }) =>
    bankAccounts.map((account) => {
      const bankName =
        bankIntegrators.find(
          (integrator) =>
            integrator.banksIntegratorsId === account.banksIntegratorsId,
        )?.bankName || '';

      const bankAccountStatus =
        dropdowns.bankAccountStatuses.find(
          (s) => s.bankAccountStatusesId === account.bankAccountStatusId,
        )?.description || '';

      return {
        ...account,
        bankName,
        bankAccountStatus,
      };
    }),
);

export const getUsedBankAccountNames = createSelector(
  [getRoot],
  ({ bankAccounts }) =>
    bankAccounts
      .filter((account) => account.erpBankAccountReferenceID)
      .map((account) => account.erpBankAccountReferenceID),
);

export const getBankAccountId = createSelector(
  [getRoot],
  ({ bankAccount }) => bankAccount?.bankAccountsId || 0,
);

export const getBankAccountConnections = createSelector(
  [getRoot],
  ({ vendorCredentials, bankAccount }) => {
    return bankAccount && vendorCredentials ? [vendorCredentials] : [];
  },
);

export const getShowConnectionForm = createSelector(
  [getRoot],
  ({ showConnectionForm }) => showConnectionForm,
);

export const getIsCreatedByCurrentPerson = createSelector(
  [getRoot, getProfile],
  ({ bankAccount }, { person }) =>
    bankAccount?.createdBy === person.emailAddress,
);

export const getBankAccountStep = createSelector([getRoot], ({ step }) => step);

export const getBankAccountFields = createSelector(
  [getRoot],
  ({ fields }) => fields,
);

export const getBankAccountModalState = createSelector(
  [getRoot],
  ({ isSuccess, isConnecting, showBankName, isBankAccountOpen }) => ({
    isSuccess,
    isConnecting,
    showBankName,
    isBankAccountOpen,
  }),
);

export const getBankAccountIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getIsRefreshing = createSelector(
  [getRoot],
  ({ isRefreshing }) => isRefreshing,
);

export const getIsSubmiting = createSelector(
  [getRoot],
  ({ isSubmiting }) => isSubmiting,
);

export const getIsFormUpdate = createSelector(
  [getRoot],
  ({ isFormUpdate }) => isFormUpdate,
);

export const getBankAccountIsConnecting = createSelector(
  [getRoot],
  ({ isConnecting }) => isConnecting,
);

export const getBankAccountIsSuccess = createSelector(
  [getRoot],
  ({ isSuccess }) => isSuccess,
);

export const getSelectedIntegrator = createSelector(
  [getRoot],
  ({ integrator }) => integrator,
);

export const getSelectedBankName = createSelector(
  [getRoot],
  ({ integrator }) => integrator?.bankName || '',
);

export const getBankIntegrators = createSelector(
  [getRoot],
  ({ bankIntegrators }) => bankIntegrators,
);

export const getBankAccountTypes = createSelector(
  [getRoot],
  ({ bankAccountTypes }) => bankAccountTypes,
);

export const getSelectedConnection = createSelector(
  [getRoot],
  ({ selectedConnection }) => selectedConnection,
);

export const getSelectedCountry = createSelector(
  [getRoot, getDashboard],
  ({ bankAccount, integrator }, { dropdowns }) => {
    const countryISOCode =
      integrator &&
      integrator.banksIntegratorsId === bankAccount?.banksIntegratorsId
        ? bankAccount.countryISOCode
        : CountryISOCodes.USA;

    return dropdowns.countries.find(
      (country) => country.value === countryISOCode,
    );
  },
);

export const getFileData = createSelector(
  [getRoot],
  ({ bankAccount, integrator }) => {
    const empty = isBankAccountEmpty({ bankAccount, integrator });

    return {
      id: empty ? 0 : bankAccount?.treasuryBlobdocumentId,
      blobFileSize: empty ? '' : bankAccount?.treasuryBlobFileSize,
      blobFileName: empty ? '' : bankAccount?.treasuryBlobFileName,
      blobFileExtension: empty ? '' : bankAccount?.treasuryBlobFileExtension,
    };
  },
);

export const getUserCalledName = createSelector(
  [getRoot],
  ({ bankAccount, integrator }) => {
    const empty = isBankAccountEmpty({ bankAccount, integrator });

    return empty ? '' : bankAccount?.userCalledName;
  },
);

export const getBankAccountJson = createSelector(
  [getRoot],
  ({ vendorCredentials }) => {
    const result = {};

    if (
      vendorCredentials &&
      Array.isArray(vendorCredentials.vendorCredentials)
    ) {
      vendorCredentials.vendorCredentials.map((cred) => {
        result[`additionalFields.${cred.id}`] = cred.value || '';
      });
    }

    return result;
  },
);

export const getBankAccountInfo = createSelector(
  [getRoot],
  ({ bankAccount, integrator }) => {
    const empty = isBankAccountEmpty({ bankAccount, integrator });

    return {
      accountName: empty ? '' : bankAccount?.accountName,
      addressLine1: empty ? '' : bankAccount?.addressLine1,
      city: empty ? '' : bankAccount?.city,
      stateOrProvince: empty ? '' : bankAccount?.stateOrProvince,
      postalCode: empty ? '' : bankAccount?.postalCode,
      countryISOCode: empty ? CountryISOCodes.USA : bankAccount?.countryISOCode,
      routingNumber: empty ? '' : bankAccount?.routingNumber,
      accountNumber: empty ? '' : bankAccount?.accountNumber,
      achCompanyId: empty ? '' : bankAccount?.achCompanyId,
      bankAccountTypesId: empty ? 0 : bankAccount?.bankAccountTypesId,
      accountNumberLast4: empty ? '' : bankAccount?.accountNumberLast4,
      userCalledName: empty ? '' : bankAccount?.userCalledName,
    };
  },
);
