import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.merchant;

export const getCurrentProcessor = createSelector(
  [getRoot],
  ({ currentProcessor }) => currentProcessor,
);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getUpdateForm = createSelector(
  [getRoot],
  ({ updateForm }) => updateForm,
);

export const getFields = createSelector([getRoot], ({ fields }) => fields);

export const getMerchant = createSelector(
  [getRoot],
  ({ merchant }) => merchant,
);

export const getFormValues = createSelector(
  [getRoot],
  ({ formValues }) => formValues,
);
