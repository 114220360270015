import { createSelector } from '@reduxjs/toolkit';

import { ProductTypeCodes } from 'entities/progress';
import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.progress;

export const getBusinessProducts = createSelector(
  [getRoot],
  ({ businessProducts }) =>
    businessProducts.filter((prod) => prod.productsIsActive),
);

export const getNewProductType = createSelector(
  [getRoot],
  ({ newProductType }) => newProductType,
);

export const getActiveProductsTypeCodes = createSelector(
  [getRoot],
  ({ businessProducts }) =>
    businessProducts
      .filter((product) => product.isActive && product.productsIsActive)
      .map((product) => product.productsTypeCode),
);

export const getProcent: any = createSelector(
  [getRoot, getActiveProductsTypeCodes],
  ({ procentByTab }, products) => {
    const { owner, businessInfo, financial, transactional, businessPlan } =
      procentByTab;

    const tabs: any = [];

    if (products.includes(ProductTypeCodes.payments)) {
      tabs.push(...[businessInfo.progress, owner.progress]);
    }

    if (products.includes(ProductTypeCodes.services)) {
      tabs.push(...[financial.progress, businessPlan.progress]);
    }

    if (products.includes(ProductTypeCodes.crossBorderPayments)) {
      tabs.push(transactional.progress);
    }

    if (tabs.length > 0) {
      return tabs.reduce((sum, a) => sum + a, 0) / tabs.length;
    }

    return null;
  },
);

export const getTabProgresses: any = createSelector(
  [getRoot],
  ({ procentByTab }) => procentByTab,
);

export const getShowErrors: any = createSelector(
  [getRoot],
  ({ showErrors }) => showErrors,
);
