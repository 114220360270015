import { createSelector } from '@reduxjs/toolkit';

import { OnboardingTabCodes, Permissions } from 'entities/dashboard';
import { PermissionSchema } from 'services/security';
import { StatusIconTypes } from 'components/Tabs';
import { RootState } from 'state/store';

import { getConnectedErpAccount } from './components/AccountingSoftware/selectors';
import { getConnectedBankAccount } from './components/BankAccounts/selectors';
import { mappedCurrencyCodes, OnboardingTab, tabList } from './utils';
import { getBPSTrack } from './components/Configuration/selectors';

export const getState = (state: RootState) => state;
export const getRoot = (state: RootState) => state.dashboard;

export const getDashboardData = createSelector(
  [getRoot],
  (dashboard) => dashboard,
);

export const getOnboardingTab = createSelector(
  [getRoot],
  ({ onboardingTab }) => onboardingTab,
);

export const getOnboardingTabCode = createSelector(
  [getRoot],
  ({ onboardingTabCode }) => onboardingTabCode,
);

export const getOnboardingTabs = createSelector(
  [getState, getConnectedBankAccount, getConnectedErpAccount, getBPSTrack],
  (
    { dashboard, progress },
    connectedBankAccount,
    connectedErpAccount,
    bpsTrack,
  ) => {
    const { onboardingTab } = dashboard;
    const { procentByTab, showErrors } = progress;

    return tabList.map(({ key, ...rest }: OnboardingTab, index: number) => {
      let statusIcon: StatusIconTypes = StatusIconTypes.progress;

      if (procentByTab[key]?.progress === 100) {
        statusIcon = StatusIconTypes.success;
      }

      if (
        showErrors[key] &&
        procentByTab[key]?.progress < 100 &&
        onboardingTab !== index
      ) {
        statusIcon = StatusIconTypes.error;
      }

      if (
        key === OnboardingTabCodes.connectors &&
        (connectedBankAccount || connectedErpAccount)
      ) {
        statusIcon = StatusIconTypes.success;
      }

      if (key === OnboardingTabCodes.configuration && bpsTrack?.bankAccountId) {
        statusIcon = StatusIconTypes.success;
      }

      return {
        ...rest,
        key,
        statusIcon,
        progress: procentByTab[key],
      };
    });
  },
);

export const getPermissions = createSelector(
  [getRoot],
  ({ permissions }) => permissions,
);

export const hasPermission = createSelector(
  [getRoot, (_, checkPermission: Permissions | undefined) => checkPermission],
  ({ permissions }, checkPermission) => ({
    view:
      permissions.findIndex(
        (p: PermissionSchema) =>
          p.permissions_Code.toLowerCase() ===
          `${checkPermission?.toLowerCase()}_view`,
      ) >= 0,
    edit:
      permissions.findIndex(
        (p: PermissionSchema) =>
          p.permissions_Code.toLowerCase() ===
          `${checkPermission?.toLowerCase()}_edit`,
      ) >= 0,
  }),
);

export const getLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getBankAccountStatuses = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.bankAccountStatuses,
);

export const getCountries = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.countries,
);

export const getApplicationStatuses = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.applicationStatus,
);

export const getBusinessStatuses = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessStatuses,
);

export const getInputCurrencies = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.inputCurrencies,
);

export const getBusinessTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessIdTypes,
);

export const getDocumentTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessesDocumentsTypes,
);

export const getPersonsDocumentsTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.personsDocumentsTypes,
);

export const getBlobReferenceTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.blobReferencesTypes,
);

export const getMappedBlobReferenceTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.mappedBlobReferencesTypes,
);

export const getBusinessLegalEntityFormTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessesLegalEntityFormTypes,
);

export const getMappedCurrencyCodes = createSelector(
  [getRoot],
  ({ dropdowns }) => mappedCurrencyCodes(dropdowns.currencyCodes),
);
