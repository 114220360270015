import { useEffect } from 'react';

import { BussinessProductsProps, ProductTypeCodes } from 'entities/progress';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';

import { fetchBusinessProducts, updateBusinessProduct } from '../thunks';
import { getBusinessProducts } from '../selectors';
import ProgressProduct from './ProgressProduct';

type ProgressProductsProps = {
  filter?: ProductTypeCodes[];
  inline?: boolean;
};

const ProgressProducts: React.FC<ProgressProductsProps> = ({
  filter = [],
  inline = false,
}) => {
  const dispatch = useAppDispatch();

  const products = useAppSelector(getBusinessProducts);
  const productPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.products),
  );

  useEffect(() => {
    dispatch(fetchBusinessProducts());
  }, [dispatch]);

  const handleClickProduct = (product: BussinessProductsProps) => {
    dispatch(
      updateBusinessProduct({
        businessesProductsXrefId: product.businessesProductsXrefId,
        isActive: !product.isActive,
        productsTypeCode: product.productsTypeCode,
      }),
    );
  };

  return (
    <>
      {products
        .filter(
          (prod: BussinessProductsProps) =>
            !filter.length || filter.includes(prod.productsTypeCode),
        )
        .sort(
          (a, b) =>
            Object.values(ProductTypeCodes).indexOf(a.productsTypeCode) -
            Object.values(ProductTypeCodes).indexOf(b.productsTypeCode),
        )
        .map((product: BussinessProductsProps) => (
          <ProgressProduct
            key={product.businessesProductsXrefId}
            item={product}
            onClick={handleClickProduct}
            inline={inline}
            permission={productPermissions.edit}
          />
        ))}
    </>
  );
};

export default ProgressProducts;
