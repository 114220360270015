import { sortBy, prop } from 'ramda';
import { ReactNode } from 'react';

import { ProductTypeCodes, TabProgressProps } from 'entities/progress';
import { lowercaseKeys, trimStr } from 'utils/helpers';
import { StatusIconTypes } from 'components/Tabs';
import {
  BlobReferencesType,
  Country,
  CurrencyCode,
  OnboardingTabCodes,
} from 'entities/dashboard';

import BusinessInformation from './components/BusinessInformation/BusinessInformation';
import ConfigurationTabPopover from './components/ConfigurationTabPopover';
import IntegrationTabPopover from './components/IntegrationTabPopover';
import Transactional from './components/Transactional/Transactional';
import OnboardingTabPopover from './components/OnboardingTabPopover';
import PlanTab from './components/BusinessPlan/BusinessPlan';
import ConfigurationTab from './components/ConfigurationTab';
import Ownership from './components/Ownership/Ownership';
import Financial from './components/Financial/Financial';
import IntegrationTab from './components/IntegrationTab';

const currencyList = ['USD', 'EUR'];

export interface OnboardingTab {
  name: string;
  statusIcon: StatusIconTypes;
  key: OnboardingTabCodes;
  component: React.ReactNode;
  product?: ProductTypeCodes;
  progress?: TabProgressProps;
  statusIconPopover?: ReactNode;
}

export const tabList: OnboardingTab[] = [
  {
    name: 'dashboard:tabs.business',
    statusIcon: StatusIconTypes.empty,
    key: OnboardingTabCodes.businessInfo,
    product: ProductTypeCodes.payments,
    component: <BusinessInformation />,
    statusIconPopover: (
      <OnboardingTabPopover code={OnboardingTabCodes.businessInfo} />
    ),
  },
  {
    name: 'dashboard:tabs.ownership',
    statusIcon: StatusIconTypes.empty,
    key: OnboardingTabCodes.owner,
    product: ProductTypeCodes.payments,
    component: <Ownership />,
    statusIconPopover: <OnboardingTabPopover code={OnboardingTabCodes.owner} />,
  },
  {
    name: 'dashboard:tabs.financial',
    statusIcon: StatusIconTypes.empty,
    key: OnboardingTabCodes.financial,
    product: ProductTypeCodes.services,
    component: <Financial />,
    statusIconPopover: (
      <OnboardingTabPopover code={OnboardingTabCodes.financial} />
    ),
  },
  {
    name: 'dashboard:tabs.transactional',
    statusIcon: StatusIconTypes.empty,
    key: OnboardingTabCodes.transactional,
    product: ProductTypeCodes.crossBorderPayments,
    component: <Transactional />,
    statusIconPopover: (
      <OnboardingTabPopover code={OnboardingTabCodes.transactional} />
    ),
  },
  {
    name: 'dashboard:tabs.plan',
    statusIcon: StatusIconTypes.empty,
    product: ProductTypeCodes.services,
    key: OnboardingTabCodes.businessPlan,
    component: <PlanTab />,
    statusIconPopover: (
      <OnboardingTabPopover code={OnboardingTabCodes.businessPlan} />
    ),
  },
  {
    name: 'dashboard:tabs.connectors',
    statusIcon: StatusIconTypes.empty,
    key: OnboardingTabCodes.connectors,
    component: <IntegrationTab />,
    statusIconPopover: <IntegrationTabPopover />,
  },
  {
    name: 'dashboard:tabs.configuration',
    statusIcon: StatusIconTypes.empty,
    key: OnboardingTabCodes.configuration,
    component: <ConfigurationTab />,
    statusIconPopover: <ConfigurationTabPopover />,
  },
];

export const mappedCurrencyCodes = (codes) =>
  sortBy(prop('label'))(
    codes.slice().map((code) => ({
      name: `${code.currencyName} (${code.alphaCode})`,
      label: `${code.currencyName} (${code.alphaCode})`,
      value: code.currencyCodeID,
      alphaCode: code.alphaCode,
    })),
  );

const mapBlobReferencesTypes = (result) => {
  const mappedBlobReferencesTypes = {};

  result.blobReferencesTypes.map((type: BlobReferencesType) => {
    mappedBlobReferencesTypes[type.typeCode] = type.blobReferencesTypesId;
  });

  result.mappedBlobReferencesTypes = mappedBlobReferencesTypes;

  return result;
};

const mapCurrencyCodes = (result) => {
  result.inputCurrencies = result.currencyCodes.filter(
    (currencyCode: CurrencyCode) =>
      currencyList.includes(currencyCode.alphaCode),
  );

  return result;
};

const mapLegalEntities = (result) => {
  result.businessesLegalEntityFormTypes =
    result.businessesLegalEntityFormTypes.map((type) => ({
      name: type.description,
      value: type.businessesLegalEntityFormTypesId,
    }));

  return result;
};

const mapCountries = (result) => {
  result.countries = result.countries.map((country: Country) => ({
    ...country,
    name: country.country,
    value: country.iSOCode,
    icon: `/assets/flags/${country.alpha2Code.toLowerCase()}.png`,
  }));

  return result;
};

interface DropdownsObject {
  [key: string]: any;
}

const parseCallBacks = {
  blobReferencesTypes: mapBlobReferencesTypes,
  businessesLegalEntityFormTypes: mapLegalEntities,
  countries: mapCountries,
  currencyCodes: mapCurrencyCodes,
};

export const getParsedDropdowns = (dropdowns) => {
  const result: DropdownsObject = {};

  for (const key in dropdowns) {
    const dropdownKey = key.replace('Json', '');

    result[dropdownKey] = dropdowns[key]
      ? lowercaseKeys(JSON.parse(dropdowns[key]))
      : [];

    if (parseCallBacks[dropdownKey]) {
      parseCallBacks[dropdownKey](result);
    }
  }

  return result;
};

export const calcProcentege = (selectedData: any) => {
  if (!selectedData) {
    return {
      totalFields: 0,
      enteredFields: 0,
      progress: 0,
    };
  }

  const allFields = Object.values(selectedData);
  const noValuesLength = allFields.filter((item) => !trimStr(item)).length;
  const progress = 100 - Math.ceil((noValuesLength * 100) / allFields.length);

  return {
    totalFields: allFields.length,
    enteredFields: allFields.length - noValuesLength,
    progress,
  };
};

export const isBusinessRegistered = (payload: any) => {
  if (!payload) {
    return false;
  }

  const { business, businessPerson, person, personTelephoneNumber } = payload;

  if (
    !businessPerson ||
    !businessPerson.businessesPersonsRole ||
    (!business.dunsnumber && !business.noDunslisted) ||
    !personTelephoneNumber.telephoneNumber ||
    !person.givenName1.trim() ||
    !person.surnameFirst.trim()
  ) {
    return false;
  }

  return true;
};
