import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.submitDashboard;

export const getIsModalOpened = createSelector(
  [getRoot],
  ({ isModalOpened }) => isModalOpened,
);

export const getAuthorizing = createSelector(
  [getRoot],
  ({ authorizing }) => authorizing,
);

export const getIsAgreed = createSelector(
  [getRoot],
  ({ isAgreed }) => isAgreed,
);
